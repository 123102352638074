import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/task/login"
  },
  {
    path: "/force",
    name: "force",
    component: () => import("../views/forceworker.vue")
  },
  {
    path: "/task",
    name: "Task",
    component: () => import("../views/Task.vue"),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
          requiresAuth: true,
          title: '登录'
        }
      },
      {
        path: 'worker',
        name: 'Worker',
        component: () => import('../views/Worker.vue'),
        meta: {
          requiresAuth: true,
          title: 'worker'
        }
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
