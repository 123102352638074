import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';





Vue.use(Vant);

Vue.config.productionTip = false
let onDebug = process.env.NODE_ENV === 'development' ? true : false
let zjrbCp = document.createElement('script')
zjrbCp.async = 'async'
zjrbCp.src = 'https://cdn-cp.tmuyun.com/jssdk/v1/zjrb-cp.v1.min.js'
zjrbCp.charset = 'UTF-8'

document.body.appendChild(zjrbCp)
zjrbCp.onload = function () {
  let CP = window.CP;
  if (CP && typeof CP.ready === 'function') {
    CP.ready({
      debug: onDebug,
      es6Require: false,
      ready: function (client_info) {
        // console.log(client_info);
        // console.log('ready')
        // client_info 为 { client_code: 'web', version: '1.0', detail: ''}  clientInfo 接口返回数据
        main(client_info)
      },
      error: function (error) {
        //error   {code: 4000, msg: res.errMsg, data: res}  res为原生报错 微信的报错也在这处理
        console.log(error)
      }
    })
  }
}
function main(client_info) {

  
  var clientCode = client_info.client_code;
  localStorage.setItem('clientCode', clientCode);
  router.beforeEach((to, from, next) => {
    document.title = to.meta.title == undefined ? '掌上温岭' : to.meta.title;
    next();

  })
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
